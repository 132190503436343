import API from "../..";
import { useQueryWrapper } from "../wrappers";
import { getConfigurationKey } from "./configuration-query-keys";

export function useQueryGetConfiguration() {
  const {
    data: configuration,
    isLoading,
    isError,
  } = useQueryWrapper({
    queryKey: getConfigurationKey(),
    queryFn: () => API.Configuration.getConfiguration(),
    staleTime: 24 * 60 * 60 * 1000,
    gcTime: 24 * 60 * 60 * 1000,
  });
  return {
    isLoading,
    configuration,
    isError,
  };
}
