import { API_BASE_URL } from "../urls.api";
import { Resource } from "@/pages/rooms/details/components/resources/types";

export const createResource = async (
  body: Omit<Resource, "_id">,
  roomId: string
): Promise<{ data: Resource }> =>
  fetch(`${API_BASE_URL}/resources/room/${roomId}`, {
    method: "post",
    credentials: "include",
    body: JSON.stringify(body),
    headers: { "Content-Type": "application/json" },
  }).then((response) => response.json());

export const createResourceToNode = async (
  body: Omit<Resource, "_id">
): Promise<{ data: Resource }> =>
  fetch(`${API_BASE_URL}/resources`, {
    method: "post",
    credentials: "include",
    body: JSON.stringify(body),
    headers: { "Content-Type": "application/json" },
  }).then((response) => response.json());

export const updateResource = async (
  resource: Partial<Resource>,
  resourceId: string
): Promise<{ data: Resource }> => {
  try {
    const response = await fetch(`${API_BASE_URL}/resources/${resourceId}/content`, {
      method: "PATCH",
      credentials: "include",
      body: JSON.stringify(resource),
      headers: { "Content-Type": "application/json" },
    });

    return response.json();
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deleteResource = async (ownerId: string, resourceId: string): Promise<Response> =>
  fetch(`${API_BASE_URL}/resources/${resourceId}/owner/${ownerId}`, {
    method: "DELETE",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  });

export const getResourcesByRoomAndModule = async (
  roomId: string,
  ownerId: string,
  module?: string
): Promise<{ data: Resource[] }> =>
  fetch(
    `${API_BASE_URL}/resources/owner/${ownerId}/room/${roomId}${module ? `?module=${module}` : ""}`,
    {
      ...(module && { search: `?module=${module}` }),
      method: "get",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    }
  ).then((response) => {
    const data = response.json();
    return data;
  });

export const getProtocolsByNode = async (
  nodeId: string,
  ownerId: string,
  module?: string
): Promise<{ data: Resource[] }> =>
  fetch(
    `${API_BASE_URL}/resources/owner/${ownerId}/node/${nodeId}${module ? `?moduleType=${module}` : ""}`,
    {
      ...(module && { search: `?moduleType=${module}` }),
      method: "get",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    }
  ).then((response) => {
    const data = response.json();
    return data;
  });

export const getGraph = async (ownerId: string, roomId: string) => {
  const response = await fetch(`${API_BASE_URL}/nodes/path/${ownerId}/room/${roomId}`, {
    method: "get",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  });
  const { data } = await response.json();

  return data;
};

export const getProtocolsByNodes = async (
  roomId: string,
  ownerId: string,
  module?: string
): Promise<{ data: Resource[] }> => {
  const nodeGraph = await getGraph(ownerId, roomId);

  const protocolsPromises = nodeGraph.map((node: { _id: string }) =>
    getProtocolsByNode(node._id, ownerId, module ?? "")
  );
  const protocolsData = await Promise.all(protocolsPromises);
  const protocols = protocolsData.map((protocolResponse) => protocolResponse.data).flat(1);
  console.log(protocols);

  return {
    data: protocols.map((protocol) => ({
      ...protocol,
      nodeName: nodeGraph.find((node: { _id: string }) => node._id === protocol.linkId).content
        .name,
    })),
  };
};

export const moveResourceToNode = async (
  resourceId: string,
  nodeId: string
): Promise<{ data: Resource & { nodeId: string } }> => {
  const response = await fetch(`${API_BASE_URL}/resources/${resourceId}/link/${nodeId}`, {
    method: "PATCH",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  });
  const { data } = await response.json();
  return {
    data: {
      ...data,
      nodeId,
    },
  };
};
