/**
 * Formats a given date into a human-readable string with day, long month name and time.
 *
 * The format includes the full month name, numeric day, and time in 24-hour format with two-digit hour and minute values.
 * Example output: "15 de enero, 14:35"
 *
 * @param {Date | string | number} dateInput - The date to format. Can be a Date object, a string, or a timestamp.
 * @param {Intl.LocalesArgument} [locale="default"] - The locale to use for formatting the date.
 * @returns {string} The formatted date string.
 *
 * @throws {Error} If the provided dateInput is invalid or cannot be parsed into a Date object.
 */
export function formatDayLongMonthTime(
  dateInput: Date | string | number,
  locale: Intl.LocalesArgument = "default"
): string {
  const date = new Date(dateInput);

  if (isNaN(date.getTime())) {
    throw new Error(
      "Invalid date input. Please provide a valid Date object, string, or timestamp."
    );
  }

  return date.toLocaleString(locale, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: undefined,
    hour12: false,
  });
}
