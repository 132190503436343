import { Device } from "../../../../models";
import { DeviceDto } from "../device-dto.model.api.services";

export const mapDeviceDTOToDevice = (data: DeviceDto): Device => {
  return {
    _id: data._id,
    monitorId: data.monitorId,
    firmware: data.firmware,
    operator: data.operator,
    status: data.status,
    createdAt: data.createdAt,
    updatedAt: data.updatedAt,
    owner: data.owner,
    roomId: data.roomId,
    roomName: data.roomName,
    customerId: data.customerId,
    ownerId: data.ownerId,
  };
};
