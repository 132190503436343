import React from "react";

import { ConfigurationEnvironmentEnum } from "../../../models";
import { StyledAppConfigurationContainer } from "./configuration.app.styles";
import { useAppConfiguration } from "./configuration.app.hook";

interface AppConfigurationWrapperProps {
  children: React.ReactNode;
}

const AppConfigurationWrapper: React.FC<AppConfigurationWrapperProps> = ({ children }) => {
  const { appConfiguration } = useAppConfiguration();

  return (
    <>
      {children}
      {appConfiguration?.environment === ConfigurationEnvironmentEnum.DEVELOPMENT && (
        <StyledAppConfigurationContainer />
      )}
    </>
  );
};

export default AppConfigurationWrapper;
