import {
  isAdminUser,
  isControllerUser,
  isCustomerUser,
  isCustomerUserMe,
  isRoleControllerOrHigher,
  isRoleHigher,
  isRoleNightAgentOrHigher,
  isCustomerOrManagerUser,
  isAdminOrControllerUser,
} from "./role-checker";

export const RoleChecker = {
  isAdminUser,
  isControllerUser,
  isCustomerUser,
  isCustomerUserMe,
  isRoleHigher,
  isRoleNightAgentOrHigher,
  isRoleControllerOrHigher,
  isCustomerOrManagerUser,
  isAdminOrControllerUser,
};
