import { ConfigurationEnvironmentEnum } from "../../../../models";
import { ConfigurationApiDTO } from "../dtos/configuration.dto";

export const mapConfiguration = (configurationDTO: ConfigurationApiDTO["data"]) => {
  const isValidEnvironment = (value: any): value is ConfigurationEnvironmentEnum =>
    value === ConfigurationEnvironmentEnum.DEVELOPMENT ||
    value === ConfigurationEnvironmentEnum.PRODUCTION;

  const environment = isValidEnvironment(configurationDTO.environment)
    ? configurationDTO.environment
    : null;
  return {
    environment,
  };
};
