export enum SORT_DIRECTION_VALUES {
  asc = "asc",
  desc = "desc",
}

export const SORT_DIRECTION = "sort_direction";
export const SORT_NAME = "sort_name";
export const PAGE = "page";
export const ROWS_PER_PAGE = 10;

export type SortDirection = keyof typeof SORT_DIRECTION_VALUES;

export type Sort<T> = {
  readonly name: keyof T;
  readonly direction: SortDirection;
  readonly handleSort: (name: keyof T, direction?: SortDirection) => void;
};

export const ENTRIES_PER_PAGE_DEFAULT = [5, 10, 15, 20, 25];
