import { AddressSuggestions } from "../../../../models";
import { AddressSuggestionsDTO } from "../dtos";

export const mapAddressSuggestionsDtoToAddressSuggestions = (
  dto: AddressSuggestionsDTO[]
): AddressSuggestions[] =>
  dto.map((addressSuggestion) => ({
    address: addressSuggestion.address,
    city: addressSuggestion.city,
    province: addressSuggestion.province,
    zipCode: addressSuggestion.zipCode,
  }));
