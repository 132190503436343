import * as React from "react";

import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";

import { GridComponent } from "@/lib/components";

interface ModalComponentProps {
  open: boolean;
  handleClose: () => void;
  header?: React.ReactNode;
  content?: React.ReactNode;
  footer?: React.ReactNode;
}

export const ModalComponent: React.FC<ModalComponentProps> = ({
  open,
  header,
  content,
  footer,
  handleClose,
}) => {
  const theme = useTheme();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-modal="true"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div>
        <GridComponent
          container
          sx={{
            position: "relative",
            backgroundColor: theme.palette.background.default,
            maxHeight: "90%",
            width: "100%",
            maxWidth: { xs: "90%", lg: 800 },
            margin: "0 auto",
            borderRadius: 1,
            boxShadow: theme.shadows[5],
            overflow: "hidden",
          }}
        >
          <GridComponent
            size={{
              xs: 12,
            }}
          >
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
                zIndex: 10,
              }}
            >
              <CloseIcon />
            </IconButton>

            <GridComponent
              size={{
                xs: 12,
              }}
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: 5,
                gap: 1,
                overflow: "auto",
                maxHeight: "80vh",
                scrollbarWidth: "none",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              {header && (
                <GridComponent
                  size={{
                    xs: 12,
                  }}
                >
                  {header}
                </GridComponent>
              )}
              {content && (
                <GridComponent
                  size={{
                    xs: 12,
                  }}
                >
                  {content}
                </GridComponent>
              )}
              {footer && (
                <GridComponent
                  size={{
                    xs: 12,
                  }}
                >
                  {footer}
                </GridComponent>
              )}
            </GridComponent>
          </GridComponent>
        </GridComponent>
      </div>
    </Modal>
  );
};
