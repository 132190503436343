import React from "react";

import { styled } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import { flexRender } from "@tanstack/react-table";

import { DataTableBodyCellComponent, DataTableHeadCellComponent } from "./components";
import { useDataTable } from "./data-table.hook";
import { DataTableProps } from "./data-table.models";
import MDBox from "../../md-box";
import MDTypography from "../../md-typography";
import { PaginationComponent } from "../../pagination-component";
import { SkeletonComponent } from "../../skeleton";

const StyledRow = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== "allowClick",
})<{ allowClick: boolean }>(({ theme, allowClick }) => ({
  cursor: allowClick ? "pointer" : "default",
  "&:hover": {
    backgroundColor: allowClick ? theme.palette.action.hover : "inherit",
  },
}));

export function DataTableComponent<T>({
  table,
  page,
  sort,
  search,
  showTotalEntries = true,
  noEndBorder = false,
  isLoading = false,
  paginationStyle = { variant: "gradient", color: "info" },
  entriesPerPage,
  onClickRow,
  styles,
  fixedHeader = false,
}: DataTableProps<T>): JSX.Element {
  const {
    headerGroups,
    rows,
    pageOptions,
    isSorteable,
    isSearchable,
    totalEntries,
    pageIndex,
    pageSize,
    currentPage,
    columns,
    searchValue,
    t,
    handleChangeEntriesPerPage,
    handleSort,
    getCanPreviousPage,
    getCanNextPage,
    handleSearchChange,
    handlePageChange,
  } = useDataTable({ table, search, page, sort });

  return (
    <TableContainer
      sx={{
        boxShadow: "none",
        maxWidth: "100%",
        position: fixedHeader ? "relative" : "inherit",
        ...styles?.table,
      }}
    >
      {entriesPerPage || isSearchable ? (
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={3}
          flexWrap={{ xs: "wrap", sm: "nowrap" }}
        >
          {entriesPerPage && (
            <MDBox display="flex" alignItems="center">
              <Autocomplete
                data-testid="data-table-entries-per-page"
                disableClearable
                getOptionLabel={(option) => option.toString()}
                value={pageSize}
                options={entriesPerPage.entries}
                onChange={(_e, newValue: number) => {
                  handleChangeEntriesPerPage(newValue);
                }}
                size="small"
                sx={{ width: "5rem" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      inputProps: {
                        ...params.inputProps,
                        readOnly: true,
                      },
                    }}
                  />
                )}
              />
              <MDTypography variant="caption" color="secondary">
                &nbsp;&nbsp;{t("entries-page")}
              </MDTypography>
            </MDBox>
          )}
          {isSearchable && (
            <MDBox width={{ xs: "100%", sm: "12rem" }} ml="auto" marginTop={{ xs: 2, sm: 0 }}>
              <TextField
                placeholder={t("search")}
                defaultValue={searchValue}
                size="small"
                fullWidth
                onChange={(e) => handleSearchChange?.(e.target.value)}
                slotProps={{
                  htmlInput: {
                    "data-testid": "data-table-search-input",
                  },
                }}
              />
            </MDBox>
          )}
        </MDBox>
      ) : null}
      <Table style={{ maxWidth: "100%" }} data-testid={table.dataTestId} stickyHeader={fixedHeader}>
        <MDBox
          component="thead"
          sx={
            fixedHeader
              ? {
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  backgroundColor: "background.paper",
                }
              : {}
          }
        >
          {headerGroups.map((headerGroup, key) => (
            <TableRow key={key} sx={styles?.header?.row}>
              {headerGroup.headers.map((header, key) => (
                <DataTableHeadCellComponent
                  styles={styles?.header?.cell}
                  key={key}
                  px={header.column.columnDef.meta?.px ?? 2}
                  py={header.column.columnDef.meta?.py ?? 1.5}
                  column={header.column}
                  onClick={
                    isSorteable && header.column.getCanSort()
                      ? () => handleSort(header.getContext().column.id, header.column.getIsSorted())
                      : undefined
                  }
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </DataTableHeadCellComponent>
              ))}
            </TableRow>
          ))}
        </MDBox>
        <TableBody sx={{ height: 20 }}>
          {isLoading
            ? Array.from({ length: pageSize }).map((_, key) => (
                <TableRow key={key} sx={styles?.body?.row}>
                  {columns.map((_, key) => (
                    <DataTableBodyCellComponent
                      childrenBoxWidth={"100%"}
                      width={"auto"}
                      maxWidth={"100%"}
                      key={key}
                    >
                      <SkeletonComponent height={30} width={150} />
                    </DataTableBodyCellComponent>
                  ))}
                </TableRow>
              ))
            : rows.map((row, key) => {
                return (
                  <StyledRow
                    key={key}
                    allowClick={!!onClickRow}
                    onClick={() => onClickRow?.(row)}
                    data-testid={"data-table-body-row"}
                    sx={{ ...styles?.body?.row }}
                  >
                    {row.getVisibleCells().map((cell, key) => (
                      <DataTableBodyCellComponent
                        width={cell.column.getSize() ? cell.column.getSize() : "auto"}
                        maxWidth={cell.column.columnDef.maxSize}
                        minWidth={cell.column.columnDef.minSize ?? cell.column.getSize()}
                        align={cell.column.columnDef.meta?.align ?? "left"}
                        px={cell.column.columnDef.meta?.px ?? 2}
                        py={cell.column.columnDef.meta?.py ?? 1.5}
                        key={key}
                        noBorder={noEndBorder && rows.length - 1 === key}
                        dataTestId={`data-table-body-cell-${cell.column.id.toLowerCase()}`}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </DataTableBodyCellComponent>
                    ))}
                  </StyledRow>
                );
              })}
        </TableBody>
      </Table>

      <PaginationComponent
        canNextPage={getCanNextPage()}
        currentPage={currentPage}
        pageSize={pageSize}
        entriesStart={pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1}
        entriesEnd={Math.min(pageSize * (pageIndex + 1), totalEntries)}
        canPreviousPage={getCanPreviousPage()}
        paginationStyle={paginationStyle}
        pageOptions={pageOptions}
        showTotalEntries={showTotalEntries}
        totalEntries={totalEntries}
        handlePageChange={handlePageChange}
      />
    </TableContainer>
  );
}
