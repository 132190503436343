import { ColumnDef } from "@tanstack/react-table";

import { ColumnType } from "../../../models/column-type.model";

interface MapTableColumnsParams<T> {
  columns: ColumnType<T>[];
  t: (key: string) => string;
}

export const mapTableColumns = <T>({ columns, t }: MapTableColumnsParams<T>): ColumnDef<T>[] => {
  return columns.map((column) => {
    return {
      ...column,
      header: t(column.header),
      meta: {
        align: column.align,
        px: column.px,
        py: column.py,
      },
    };
  });
};
