import { getUpdateRoomByMonitorIdUrl } from "./api-url.constants";
import { UpdateRoomError, UpdateRoomSalesforceError } from "./errors";
import { API_METHODS } from "../shared";
import {
  UpdateRoomByMonitorIdApiDto,
  UpdateRoomByMonitorIdResonse,
} from "./dtos/update-room-by-monitor-id.api.dto";
import { UPDATE_ROOM_SALESFORCE_ERROR_MESSAGE } from "./errors/error-message.constants";
import { typedFetch } from "@/lib/helpers";

export const updateRoomByMonitorId = async (
  bodyDTO: UpdateRoomByMonitorIdApiDto
): Promise<string> => {
  const { monitorId, ...body } = bodyDTO;
  const result = await typedFetch(getUpdateRoomByMonitorIdUrl(monitorId), {
    method: API_METHODS.PUT,
    body: JSON.stringify(body),
  });

  const responseJson = await result.json();

  if (result.ok) {
    const response = responseJson as { data: UpdateRoomByMonitorIdResonse };
    return response.data.roomId;
  }

  if ("error" in responseJson) {
    const message = Array.isArray(responseJson.message)
      ? responseJson.message[0]
      : responseJson.message;
    if (message === UPDATE_ROOM_SALESFORCE_ERROR_MESSAGE) throw new UpdateRoomSalesforceError();
    throw new UpdateRoomError();
  }

  return "";
};
