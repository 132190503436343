import { AddressSuggestions } from "../../../models";
import { getAddressSuggestionsUrl } from "./api-url.constants";
import { AddressSuggestionsApiDTO } from "./dtos";
import { mapAddressSuggestionsDtoToAddressSuggestions } from "./mappers";
import { jsonFetch } from "@/lib/helpers";

export interface GetAddressSuggestionsParams {
  address: string;
  countryName: string;
}

export const getAddressSuggestions = async ({
  address,
  countryName,
}: GetAddressSuggestionsParams): Promise<AddressSuggestions[]> => {
  const response = await jsonFetch<AddressSuggestionsApiDTO>(
    getAddressSuggestionsUrl(address, countryName)
  );

  return mapAddressSuggestionsDtoToAddressSuggestions(response.data);
};
