import { getByOwnerIdRoomsUrl } from "./api-url.constants";
import { RoomByOwnerApiDTO } from "./dtos/read-room.api.dto";
import { mapRoomsByOwnerId } from "./mappers/map-rooms-by-owner-id.api.services";
import { jsonFetch } from "../../../lib/helpers";
import { Room } from "../../../models";

export const getByOwnerIdRooms = async (id?: string): Promise<Pick<Room, "_id" | "name">[]> => {
  const response = await jsonFetch<RoomByOwnerApiDTO>(getByOwnerIdRoomsUrl(id));
  return response.data.rooms.map(mapRoomsByOwnerId);
};
