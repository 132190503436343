import { GET_CONFIGURATION_URL } from "./api-url.constants";
import { jsonFetch } from "@/lib/helpers";
import { ConfigurationApiDTO } from "./dtos/configuration.dto";
import { Configuration } from "../../../models";
import { mapConfiguration } from "./mappers/configuration.mapper";

export const getConfiguration = async (): Promise<Configuration> => {
  const availableDeviceVersion = await jsonFetch<ConfigurationApiDTO>(GET_CONFIGURATION_URL);
  return mapConfiguration(availableDeviceVersion.data);
};
