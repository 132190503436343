import { WRONG_CREDENTIALS_ERROR } from "../../services/API/auth/login/errors/error-codes.constants";
import {
  DEVICE_NOT_FOUND_ERROR,
  DEVICE_UPDATE_ERROR,
} from "../../services/API/devices/errors/codes.constants.error";
import ApiError from "../../services/API/errors/ApiError";
import { ROOM_NOT_FOUND_ERROR, SWAP_ROOM_ERROR } from "../../services/API/rooms/errors";
import { useNotification } from "../contexts";

const useApiError = () => {
  const { error } = useNotification();
  const handleErrors = (err: ApiError) => {
    let message = "errors.unexpected";
    if (err.code === WRONG_CREDENTIALS_ERROR) {
      message = "errors.wrong-credentials";
    } else if (err.code === DEVICE_NOT_FOUND_ERROR) {
      message = "errors.device-not-found";
    } else if (err.code === DEVICE_UPDATE_ERROR) {
      message = "errors.device-update";
    } else if (err.code === SWAP_ROOM_ERROR) {
      message = "errors.swap-room";
    } else if (err.code === ROOM_NOT_FOUND_ERROR) {
      message = "errors.no-room";
    }

    error(message);
  };

  return {
    handleErrors,
  };
};

export default useApiError;
