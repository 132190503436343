import React from "react";

import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment"; // Asegúrate de importar esto
import { Spanish } from "flatpickr/dist/l10n/es.js";
import Flatpickr, { DateTimePickerProps } from "react-flatpickr";

import { useInternationalization } from "../../contexts";
import MDInput, { MDInputProps } from "../md-input";
import "flatpickr/dist/flatpickr.css";

interface DatePickerComponentProps extends DateTimePickerProps {
  input?: MDInputProps;
  loading?: boolean;
}

export const DatePickerComponent: React.FC<DatePickerComponentProps> = ({
  input = {},
  loading,
  ...rest
}) => {
  const { currentLanguage } = useInternationalization();
  return (
    <Flatpickr
      {...rest}
      options={{
        ...rest.options,
        ...(currentLanguage === "es" ? { locale: Spanish } : {}),
      }}
      style={{
        height: 42,
      }}
      render={(_, ref) => (
        <MDInput
          {...input}
          sx={(theme) => ({
            "&.MuiFormControl-root": {
              height: theme.spacing(5.25),
              boxSizing: "border-box",
              opacity: 1,
            },
            "& .MuiInputBase-root ": {
              height: theme.spacing(5.25),
              color: theme.palette.linearGrey.main,
              opacity: 1,
              paddingRight: "0px !important",
              "& fieldset": {
                border: "none",
              },
            },
            "& .MuiInputBase-input": {
              fontSize: theme.spacing(1.75),
              opacity: 1,
              backgroundColor: theme.palette.white.main,
              padding: `${theme.spacing(1.2425)} ${theme.spacing(1.875)}`,
              border: `1px solid ${theme.palette.linearGrey.main}`,
              borderRadius: theme.spacing(1),
              fontWeight: 400,
              lineHeight: theme.spacing(2.45),
              color: "inherit",
            },
          })}
          inputRef={ref}
          allowErrors={false}
          disabled={loading}
          InputProps={{
            endAdornment: loading ? (
              <InputAdornment
                position="end"
                sx={{
                  marginRight: 0,
                  position: "absolute",
                  right: 12,
                  pointerEvents: "none",
                }}
              >
                <CircularProgress size={20} color="primary" />
              </InputAdornment>
            ) : undefined,
          }}
        />
      )}
    />
  );
};
