import { getProtocolsByNode } from "../resources/crud.services";
import { API_BASE_URL } from "../urls.api";
import { Node } from "@/pages/users/details/details-customer/components/nodes/nodes.types";

export const getGraphByOwner = async (ownerId: string): Promise<Node[]> => {
  const graphResponse = await fetch(`${API_BASE_URL}/nodes/graph/${ownerId}`, {
    method: "get",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  });
  const { data: graphData } = await graphResponse.json();

  // Get resources for each node
  const resourcePromises = graphData.map(async (node: Node) => {
    const { data: resourceData } = await getProtocolsByNode(node?._id ?? "", node.ownerId ?? "");
    return {
      ...node,
      resourcesCount: resourceData.length,
    };
  });

  const data = await Promise.all(resourcePromises);

  return data;
};

export const createNode = async (node: Node): Promise<Node> => {
  const response = await fetch(`${API_BASE_URL}/nodes`, {
    method: "POST",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(node),
  });
  const { data } = await response.json();
  return data;
};

export const updateNodeContent = async (nodeId: string, node: Partial<Node>): Promise<Node> => {
  const response = await fetch(`${API_BASE_URL}/nodes/${nodeId}/content`, {
    method: "PATCH",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(node),
  });
  const { data } = await response.json();
  return data;
};

export const deleteNode = async (nodeId: string, ownerId: string): Promise<Node> => {
  const response = await fetch(`${API_BASE_URL}/nodes/${nodeId}/owner/${ownerId}`, {
    method: "DELETE",
    credentials: "include",
  });
  const { data } = await response.json();
  return data;
};

export const moveNode = async (nodeId: string, linkId: string): Promise<Node> => {
  const response = await fetch(`${API_BASE_URL}/nodes/${nodeId}/link/${linkId}`, {
    method: "PATCH",
    credentials: "include",
  });
  const { data } = await response.json();
  return data;
};
