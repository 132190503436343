import { getAlarmsByRoomIdAndRangeUrl } from "./api-url.constants";
import { AlarmsByRoomIdApiDTO } from "./dtos/alarms-by-room-id.api.dto";
import { mapAlarmsApiDTO } from "./mappers";
import { Alarm } from "../../../models/alarm";
import { jsonFetch } from "@/lib/helpers";

interface GetAlarmsByRoomIdAndRangeParams {
  roomId: string;
  from: number;
  to: number;
}

export const getAlarmsByRoomIdAndRage = async ({
  roomId,
  from,
  to,
}: GetAlarmsByRoomIdAndRangeParams): Promise<Alarm[]> => {
  const alarmsDTO = await jsonFetch<AlarmsByRoomIdApiDTO>(
    getAlarmsByRoomIdAndRangeUrl(roomId, from, to)
  );
  return mapAlarmsApiDTO(alarmsDTO.data);
};
