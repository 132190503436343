import { GET_DEVICE_BY_MONITOR_ID } from "./api-url.constants";
import { DeviceDto } from "./device-dto.model.api.services";
import { DeviceNotFoundError } from "./errors";
import { RoomNotFoundError } from "./errors/room-not-found.error";
import { mapDeviceDTOToDevice } from "./mappers";
import { jsonFetch } from "../../../lib/helpers";
import { Device } from "../../../models";
import { ROOM_NOT_FOUND_ERROR_MESSAGE } from "../rooms/errors/error-message.constants";

export const getByMonitorId = async (monitorId: string): Promise<Device> => {
  const response = await jsonFetch<{ data: DeviceDto }>(
    `${GET_DEVICE_BY_MONITOR_ID}/${monitorId}`
  ).catch((e) => {
    const errorMessage = e.message;
    if (errorMessage === ROOM_NOT_FOUND_ERROR_MESSAGE) throw new RoomNotFoundError();
    throw new DeviceNotFoundError();
  });

  return mapDeviceDTOToDevice(response.data);
};
