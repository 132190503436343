import React from "react";

import { StyledTitleIconCard, StyledTitleIconCardHeaderBox } from "./title-icon-card.styles";
import { SkeletonComponent } from "../skeleton";

interface TitleIconCardProps {
  children: React.ReactNode;
  animation?: "pulse" | "wave" | false;
  borderInCard?: boolean;
}

export const TitleIconCardPlaceholder: React.FC<TitleIconCardProps> = ({
  children,
  animation,
  borderInCard,
}) => {
  return (
    <StyledTitleIconCard ownerState={{ border: !!borderInCard }}>
      <StyledTitleIconCardHeaderBox ownerState={{ rightAdornment: false }}>
        <SkeletonComponent animation={animation} height={40} width={40} variant="rounded" />
        <SkeletonComponent animation={animation} height={40} width={"100%"} variant="rounded" />
      </StyledTitleIconCardHeaderBox>
      {children}
    </StyledTitleIconCard>
  );
};
