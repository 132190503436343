import { typedFetch } from "@/lib/helpers";
import { API_METHODS } from "../shared";
import { UPDATE_DEVICE_URL } from "./api-url.constants";
import { UpdateDeviceApiDto } from "./dtos";
import { DeviceUpdateError } from "./errors";

export const updateDeviceByMonitorId = async (data: UpdateDeviceApiDto): Promise<void> => {
  const { monitorId, ...body } = data;

  const response = await typedFetch(`${UPDATE_DEVICE_URL}/${monitorId}`, {
    method: API_METHODS.PUT,
    body: JSON.stringify(body),
  });

  if (!response.ok) throw new DeviceUpdateError();
};
