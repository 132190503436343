import { styled } from "@mui/material";

export const StyledAppConfigurationContainer = styled("div")({
  position: "relative",
  "::after": {
    content: '""',
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    border: "10px dashed lime",
    pointerEvents: "none",
    zIndex: 9999,
  },
});
