import { styled, Theme } from "@mui/material";

import { CardComponent } from "../card";
import MDBox from "../md-box";
import MDTypography from "../md-typography";

export const StyledTitleIconCard = styled(CardComponent)<{ ownerState: { border: boolean } }>(
  ({ theme, ownerState }) => ({
    display: "flex",
    flexDirection: "column",
    gap: 1,
    ...(ownerState?.border ? { border: `1px solid ${theme.palette.gray[1]}` } : {}),
  })
);

export const StyledTitleIconCardHeaderBox = styled(MDBox)(
  ({
    theme,
    ownerState,
  }: {
    theme: Theme;
    ownerState?: { rightAdornment: boolean; noBoxShadow: boolean };
  }) => ({
    display: "flex",
    alignItems: "center",
    ...(ownerState?.rightAdornment
      ? {
          justifyContent: "space-between",
          flexWrap: "wrap",
        }
      : {}),
    gap: theme.spacing(2),
    height: theme.spacing(9),
    padding: theme.spacing(2),
    boxShadow: ownerState?.noBoxShadow ? "none" : `0px 1px 0px 0px ${theme.palette.card.divider}`,
    [theme.breakpoints.down("md")]: {
      height: "auto",
    },
  })
);

export const StyledTitleIconCardHeaderIconBox = styled(MDBox)(({ theme }) => ({
  width: theme.spacing(5),
  height: theme.spacing(5),
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(1),
  color: theme.palette.dark.main,
  backgroundColor: theme.palette.icons.background,
}));

export const StyledTitleIconCardHeaderTypography = styled(MDTypography)(({ theme }) => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
  lineClamp: 2,
  color: theme.palette.gray[10],
}));

export const StyledTitleIconCardHeaderRightAdornmentBox = styled(MDBox)({
  display: "flex",
  marginLeft: "auto",
});
