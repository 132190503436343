import { typedFetch } from "@/lib/helpers";
import { API_METHODS } from "../shared";
import { swapMonitorUrl } from "./api-url.constants";
import { SwapRoomError } from "./errors";

interface SwapMonitorParams {
  roomId: string;
  monitorId: string;
}

export const swapMonitor = async ({ roomId, monitorId }: SwapMonitorParams): Promise<void> => {
  const response = await typedFetch(swapMonitorUrl(roomId, monitorId), {
    method: API_METHODS.PUT,
  });

  if (!response.ok) throw new SwapRoomError();
};
