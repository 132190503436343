export type AtLeastOne<T, U = { [K in keyof T]: Pick<T, K> }> = Partial<T> & U[keyof U];

export type KeyValue = {
  key: string;
  value: string;
};

export enum BOOLEAN_STRING_VALUES {
  true = "true",
  false = "false",
}
