/**
 * Converts URL search parameters to a filter status object.
 *
 * This function takes a string of URL search parameters and converts it into a
 * filter status object of a generic type `T`. It does this by creating a `URLSearchParams`
 * object from the input string, iterating over its keys, and accumulating them into an
 * object. Certain keys (`page`, `itemsPerPage`, `sortField`, `sortDirection`) are
 * explicitly excluded from this process. The result is a partial object of type `T`,
 * containing only the keys and values from the URL search parameters that are not
 * excluded.
 *
 * @param {string} urlParams - A string of URL search parameters to be converted into
 * a filter status object.
 *
 * @returns {Partial<T>} - A partial object of type `T` representing the filter status,
 * containing keys and values derived from the URL search parameters, excluding specific
 * keys related to pagination and sorting.
 *
 * @example
 * // Assuming `urlParams` is a string that represents URL search parameters.
 * const filterStatus = mapUrlParamsToFilterStatus<MyType>(urlParams);
 * // `filterStatus` is now an object of type `MyType` with properties derived from `urlParams`.
 */

export const mapUrlParamsToFilterStatus = <T>(urlParams: string): Partial<T> => {
  const params = new URLSearchParams(urlParams);
  return Array.from(params.keys()).reduce(
    (result, key) => {
      if (
        key !== "page" &&
        key !== "itemsPerPage" &&
        key !== "sortField" &&
        key !== "sortDirection"
      ) {
        return { ...result, [key]: params.get(key) };
      } else {
        return result;
      }
    },
    {} as Partial<T>
  );
};
