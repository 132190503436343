import { useEffect, useState } from "react";
import { AppConfiguration } from "./configuration.app.models";
import { useQueryGetConfiguration } from "../../../services/API/hooks/configuration-hooks/get-configuration.hooks.api.services";
import { configurationAppMapper } from "./configuration.app.mapper";

export const useAppConfiguration = () => {
  const [appConfiguration, setAppConfiguration] = useState<AppConfiguration | null>(null);

  const { configuration } = useQueryGetConfiguration();

  useEffect(() => {
    configuration && setAppConfiguration(configurationAppMapper(configuration));
  }, [configuration]);

  return {
    appConfiguration,
  };
};
