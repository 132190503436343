import React from "react";

import {
  StyledTitleIconCard,
  StyledTitleIconCardHeaderBox,
  StyledTitleIconCardHeaderIconBox,
  StyledTitleIconCardHeaderRightAdornmentBox,
  StyledTitleIconCardHeaderTypography,
} from "./title-icon-card.styles";
import { MaterialSymbolComponent } from "../material-symbol";
import MDBox from "../md-box";

interface TitleIconCardProps {
  title: string;
  icon: string;
  fillIcon?: boolean;
  iconColor?: string;
  rightAdornment?: React.ReactNode;
  noBoxShadow?: boolean;
  borderInCard?: boolean;
  children: React.ReactNode;
}

export const TitleIconCardComponent: React.FC<TitleIconCardProps> = ({
  title,
  icon,
  fillIcon = false,
  children,
  rightAdornment,
  iconColor = "inherit",
  noBoxShadow = false,
  borderInCard = false,
}) => {
  return (
    <StyledTitleIconCard ownerState={{ border: !!borderInCard }}>
      <StyledTitleIconCardHeaderBox ownerState={{ rightAdornment: !!rightAdornment, noBoxShadow }}>
        <MDBox display="flex" alignItems="center" gap={2}>
          <StyledTitleIconCardHeaderIconBox>
            <MaterialSymbolComponent
              symbol={icon}
              fill={fillIcon}
              styles={{
                color: iconColor,
              }}
            />
          </StyledTitleIconCardHeaderIconBox>
          <StyledTitleIconCardHeaderTypography variant="h6" fontWeight="medium">
            {title}
          </StyledTitleIconCardHeaderTypography>
        </MDBox>
        {rightAdornment && (
          <MDBox display="flex" alignItems="center">
            <StyledTitleIconCardHeaderRightAdornmentBox>
              {rightAdornment}
            </StyledTitleIconCardHeaderRightAdornmentBox>
          </MDBox>
        )}
      </StyledTitleIconCardHeaderBox>
      {children}
    </StyledTitleIconCard>
  );
};
