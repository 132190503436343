import { useQueryClient } from "@tanstack/react-query";

export function useQueryClientWrapper() {
  const queryClient = useQueryClient();

  const handleInvalidateQuery = (key: string[]) => {
    queryClient.invalidateQueries({ queryKey: key });
  };
  return { handleInvalidateQuery };
}
